<template>
 <div>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 60 60">
  <defs>
    <clipPath id="clip-pathwer">
      <path id="Path_280" data-name="Path 280" style="fill: #fff;" d="M0,0H23.352V23.352H0Z" transform="translate(290 608)"/>
    </clipPath>
  </defs>
  <g id="Skype" transform="translate(-290 -609)">
    <g id="Skype-2" data-name="Skype">
      <path id="Path_279" data-name="Path 279"  style="fill: #fff;" d="M0,0H60V60H0Z" transform="translate(290 609)"/>
      <g id="Mask_Group_6" data-name="Mask Group 6" style="clip-path: url(#clip-pathwer);" transform="translate(18.324 11.648)">
        <path id="skype-3" data-name="skype" style="fill: #03a9f4;" d="M22.675,14.148A11.059,11.059,0,0,0,9.893.973,6.467,6.467,0,0,0,0,6.364,6.279,6.279,0,0,0,.887,9.585a11.062,11.062,0,0,0,12.949,13,6.513,6.513,0,0,0,3.054.756A6.376,6.376,0,0,0,22.675,14.148ZM11.767,19.335c-2.278,0-4.5-.55-5.821-2.431-1.963-2.776.626-4.166,1.9-3.107,1.06.894.728,3.057,3.842,3.057,1.373,0,3.067-.586,3.067-1.947,0-2.738-6.075-1.443-8.491-4.428A3.663,3.663,0,0,1,6.3,6.057c1.9-2.444,7.471-2.593,9.867-.747,2.212,1.712,1.615,3.985-.17,3.985-2.147,0-1.019-2.809-4.485-2.809-2.513,0-3.5,1.787-1.732,2.657,2.355,1.169,8.512.8,8.512,5.451C18.28,17.858,15.129,19.335,11.767,19.335Z" transform="translate(290 608.007)"/>
      </g>
    </g>
    <text id="Skype-4" data-name="Skype" style="font-size: 11px;font-family: ArialMT, Arial;" transform="translate(320 656)"><tspan x="-15.286" y="0">Skype</tspan></text>
  </g>
</svg>

 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>